import { theme as antdTheme } from "antd";

export const IS_DEV = false;

export const theme = Object.freeze({
  token: {
    colorPrimary: "#c59ef8",
    colorBgLayout: "transparent",
    colorLink: "#c59ef8",
    colorTextLabel: "#fff",
    colorTextHeading: "#fff",
    fontSize: 16,
    colorBgContainer: "#301e3f",
    boxShadow: "none",
    borderRadius: 4,
    colorTextPlaceholder: "#9b9b9b",
    colorBorder: "transparent",
    colorBorderBg: "transparent",
    // lineWidth: 0,
    controlHeightLG: 48,
    controlHeightSM: 32,
    controlHeightXS: 24,
    colorIcon: "#fff",
  },
  components: {
    Input: {
      colorText: "#fff",
      colorIcon: "#fff",
    },
    Typography: {
      colorText: "#fff",
    },
    Divider: {
      lineWidth: 1,
      colorSplit: "#fff",
    },
    Select: {
      colorText: "#fff",
      colorTextDisabled: "#4f4f4f",
      colorTextQuaternary: "#fff",
      colorBgElevated: "#1a1125",
      controlItemBgActive: "#301d3f",
    },
    Button: {
      colorText: "#fff",
    },
    Segmented: {
      colorFillSecondary: "rgba(255, 255, 255, 0.06)",
      colorBgElevated: "rgba(255, 255, 255, 0.1)",
    },
    Table: {
      colorBorderSecondary: "#656565",
      lineWidth: 1,
    },
  },
  algorithm: antdTheme.darkAlgorithm,
});

// Generator: https://ant.design/theme-editor

// Screen endpoints:
// {
//   xs: '480px',
//   sm: '576px',
//   md: '768px',
//   lg: '992px',
//   xl: '1200px',
//   xxl: '1600px',
// }

export const CURRENCY = Object.freeze({
  USDT: "USDT",
  BCH: "BCH",
  BTC: "BTC",
  ETH: "ETH",
  USD: "USD",
  DASH: "DASH",
  LTC: "LTC",
  EUR: "EUR",
});
export const TRANSACTION_STATUS = Object.freeze({
  NEW: "new",
  PAID: "paid",
  WAIT_OUT_PAYMENT: "wait_out_payment",
  DONE: "done",
  CANCEL: "cancel",
  CHECKED: "checked",
  ERROR_DONE: "error_done",
});
export const TRANSACTION_TYPE = Object.freeze({
  BUY: "BUY",
  SELL: "SELL",
  SEND: "SEND",
  RECEIVE: "RECEIVE",
  SEND_FIAT: "SEND_FIAT",
});
export const VERIFICATION_STATUS = Object.freeze({
  NO_VERIFY: null,
  IN_PROGRESS: "in_progress",
  VERIFIED: "verify",
  REJECTED: "reject",
});
export const AML_STATUS = Object.freeze({
  NOT_CHECKED: "NOT_CHECKED",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  MANUAL: "MANUAL",
});
export const directions = [
  // { id: 1, name: CURRENCY.LTC, disabled: true },
  // { id: 2, name: CURRENCY.DASH, disabled: true },
  // { id: 3, name: CURRENCY.BTC, disabled: true },
  // { id: 4, name: CURRENCY.USD, disabled: true },
  { id: 5, name: "USDT (ERC 20)", disabled: false },
  // { id: 6, name: CURRENCY.BCH, disabled: true },
  { id: 7, name: CURRENCY.USDT + " (TRC20)", disabled: false },
];
