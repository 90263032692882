import React from 'react';
import Container from "../../components/Container/Container";
import {Button, Col, Form, Grid, Input, Row, Space, Typography} from "antd";
import classes from "./Contacts.module.scss"
import privacy from "../../assets/privacy_policy.pdf";
import terms from "../../assets/terms.pdf";
import {sendContactForm} from "../../store/actions/user";
import {FIELDS_SCHEME, validateMessages} from "../../schemas";
import {useDispatch, useSelector} from "react-redux";
import {getUserMeta} from "../../store/selectors";

const Contacts = () => {
	const [form] = Form.useForm();
	const breakpoints = Grid.useBreakpoint();
	const dispatch = useDispatch();
	const {first_name, last_name} = useSelector(getUserMeta);
	console.log({first_name, last_name})

	const onFormSubmitSuccess = (formData) => {
		dispatch(sendContactForm(formData, "contacts")).then(() => form.resetFields())
	};

	return (
		<Container>
			<Row gutter={[48,48]}>
				<Col xs={24} lg={12}>
					<div>
						<Typography.Title level={breakpoints.md ? 1 : 3}>Contacts</Typography.Title>
						<Typography.Paragraph>
							CryptoLite is a secure, reliable and fully regulated cryptocurrency exchange based in Lithuania.
							Our company provides a safe platform to buy and sell various cryptocurrencies including Bitcoin,
							Ethereum, Litecoin, Ripple, Dash, Bitcoin Cash and Tether. Advanced technologies and professional
							expertise make for a superior exchange experience for all our customers.
						</Typography.Paragraph>
						<Typography.Title level={breakpoints.md ? 3 : 4}>For correspondence</Typography.Title>
						<ul>
							<li><Typography.Text strong>Address: </Typography.Text><Typography.Text>
								J. Savickio g. 4-7, LT-01108 Vilnius, Lithuania
							</Typography.Text></li>
							<li><Typography.Text strong>Phone: </Typography.Text><Typography.Text>
								+372 5599-1403
							</Typography.Text></li>
							<li><Typography.Text strong>E-mail: </Typography.Text><Typography.Text>
								contact@kryptolite.lt
							</Typography.Text></li>
						</ul>
						<div className={classes.mapContainer}>
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2306.0772119480025!2d25.268196000000003!3d54.690668900000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46dd9408940c0747%3A0xe8596f7df07a508a!2zSi4gU2F2aWNraW8gZy4gNC03LCAwMzEwOCBWaWxuaXVzLCDQm9C40YLQstCw!5e0!3m2!1sru!2sru!4v1708539051629!5m2!1sen!2sen"
								width="100%" height="350" allowFullScreen="" loading="lazy"
								referrerPolicy="no-referrer-when-downgrade"></iframe>
						</div>

					</div>
				</Col>
				<Col xs={24} lg={12}>
					<div className={classes.formContainerWrapper}>
						<div className={classes.formContainer}>
							<Typography.Title level={breakpoints.md ? 3 : 4}>Get in touch with us</Typography.Title>
							<Form initialValues={{ name: first_name && last_name ? [first_name, last_name].join(' ') : '' }} validateMessages={validateMessages} layout="vertical" form={form} size="large"
								  onFinish={onFormSubmitSuccess} labelWrap>
								<Form.Item label="Name" name="name" rules={[...FIELDS_SCHEME.fullName, {required: true}]}>
									<Input placeholder="Your name" />
								</Form.Item>
								{/*<Form.Item label="Email" name="email" rules={[...FIELDS_SCHEME.email, ...GENERAL_SCHEME.required]}>*/}
								{/*	<Input disabled placeholder="Your email"/>*/}
								{/*</Form.Item>*/}
								<Form.Item label="Text" name="comment" rules={[{ required: true }]}>
									<Input.TextArea maxLength={500} placeholder="Type your question..." autoSize={{
										minRows: 3, maxRows: 6
									}}/>
								</Form.Item>

								<Space className="w100" direction="vertical" align="center" size="middle">
									<Button onClick={form.submit} type="primary" size="large">SEND</Button>
									<Typography.Paragraph style={{ fontSize: 12, textAlign: 'center' }}>
										By continuing you accept our standard <Typography.Link style={{ fontSize: 12 }} href={terms} download="terms_and_conditions.pdf">terms & conditions </Typography.Link>
										and our <Typography.Link style={{ fontSize: 12 }} href={privacy} download="policy.pdf">privacy policy</Typography.Link>.
									</Typography.Paragraph>
								</Space>
							</Form>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default Contacts;
