import { Badge, Button, Flex, message, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import classes from './ProfileAvatar.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserAvatar, getUserAvatar, postUserAvatar } from "../../store/actions/user";
import UserAvatar from "./UserAvatar";

const ProfileAvatar = () => {
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const url = useSelector((state) => state.user.avatarUrl);

  useEffect(() => {
    setAvatar();

    const input = document.getElementById('avatar-upload');
    input?.addEventListener('change', () => {
      if (input?.files?.length === 1) {
        const file = input?.files[0];
        if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg')
          uploadAvatar(input?.files[0]);
      }
    });
  }, []);

  const setAvatar = async () => {
    setLoading(true);
    try {
      await dispatch(getUserAvatar());
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const getBase64 = async (file) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        try {
          resolve(reader.result);
        } catch (err) {
          reject(err);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });

  }

  const uploadAvatar = async (file) => {
    const fileUrl = await getBase64(file);

    setLoading(true);
    try {
      await dispatch(postUserAvatar(fileUrl));
      await setAvatar();
    } catch (err) {
      console.log(err);
      message.error({
        content: 'Avatar upload error',
      });
    } finally {
      setLoading(false)
    }
  };

  const deleteAvatar = async () => {
    setLoading(true);
    try {
      await dispatch(deleteUserAvatar());
    } catch (err) {
      console.log(err);
      message.error({
        content: 'Avatar delete error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div style={{position: 'relative'}}>
        <input type="file" id="avatar-upload" style={{ display: 'none' }} />
        <div
          onClick={() => setModalOpen(true)}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 3
          }}
        >
          <Badge style={{ cursor: 'pointer' }} count={
            <div className={classes['pen-icon']}>
              <EditOutlined style={{ fontSize: 20 }} />
            </div>
          } />
        </div>
        <UserAvatar url={url} size={120} loading={loading}/>
      </div>
      <Modal
        width={300}
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={
          <Flex vertical>
            <label htmlFor="avatar-upload" style={{zIndex: 1}}>
              <div className={classes['edit-btn']}>
                {url ?
                  <p>Edit</p> :
                  <p>Upload</p>
                }
              </div>
            </label>
            {url &&
              <Button type='text' onClick={deleteAvatar}>
                Delete
              </Button>
            }
          </Flex>
        }
      >
        <UserAvatar url={url} size={240} loading={loading} />
      </Modal>
    </>
  )
}

export default ProfileAvatar;