import { Form, Image, Select, Space, Typography } from "antd";
import React from "react";

const CurrencySelector = ({ name, arr }) => {
  return (
    <Form.Item name={name} style={{ marginBottom: 0 }}>
      <Select style={{ width: 200 }} options={arr.map(i => ({
        label: (
          <Space align="center">
            <Image preview={false} width={32} height={32} src={i.src} alt="currency" />
            <Typography.Text>{i.label}</Typography.Text>
          </Space>
        ),
        value: i.value,
      }))} />
    </Form.Item>
  )
}

export default CurrencySelector;
