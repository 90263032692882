import React, { useEffect, useMemo, useState } from "react";
import classes from "./SideBar.module.scss";
import {
  Button,
  Col,
  Divider,
  Image,
  Grid,
  Row,
  Space,
  Typography,
} from "antd";
import { usePersistor } from "../../hooks/usePersistor";
import { useNavigate } from "react-router-dom";
import { TbCoins } from "react-icons/tb";
import { ROUTES } from "../AppRouter/AppRouter";
import { MdCallMade, MdCallReceived } from "react-icons/md";
import { AiOutlineHistory } from "react-icons/ai";
import Container from "../Container/Container";
import { connect } from "react-redux";
import { getTrade } from "../../store/selectors";
import { formatNumberPrecision } from "../../utils/helpers";

import usdtIcon from "../../assets/svg/tether-usdt-logo.svg";
import eurIcon from "../../assets/svg/euro_coin.svg";
import ethIcon from "../../assets/svg/eth_coin.svg";
import tronIcon from "../../assets/svg/tron-trx-logo.svg";
import { EuroCircleOutlined } from "@ant-design/icons";
import PlusIcon from "../PlusIcon/PlusIcon";

const SideBar = ({ user, usdtCourse, ethCourse }) => {
  const persistor = usePersistor();
  const navigate = useNavigate();
  const breakpoints = Grid.useBreakpoint();

  const cryptoServicesLinks = useMemo(() => {
    return [
      {
        key: "fiat",
        label: "Fiat transfer",
        icon: <EuroCircleOutlined />,
        route: ROUTES.FIAT_EXCHANGE,
      },
      {
        key: "trade",
        label: "Buy/Sell Crypto",
        icon: <TbCoins />,
        route: ROUTES.EXCHANGE,
      },
      {
        key: "send",
        label: "Send Crypto",
        icon: <MdCallMade />,
        route: ROUTES.SEND,
      },
      {
        key: "receive",
        label: "Receive Crypto",
        icon: <MdCallReceived />,
        route: ROUTES.RECEIVE,
      },
      {
        key: "history",
        label: "Transaction History",
        icon: <AiOutlineHistory />,
        route: ROUTES.HISTORY,
      },
    ];
  }, []);

  return (
    <Container>
      <Space className="w100" size="large" direction="vertical">
        <div className={classes.sideBarContainer}>
          <Space className="w100" direction="vertical">
            <Divider orientation="center" style={{ margin: 0 }}>
              Balance
            </Divider>
            <Row justify="space-between">
              <Row align="middle">
                <Typography.Text>FIAT (EUR)</Typography.Text>
                <PlusIcon
                  className={classes.plusIcon}
                  onClick={() => navigate(ROUTES.FIAT_EXCHANGE)}
                />
              </Row>
              <Row align="middle">
                <Typography.Text>
                  {formatNumberPrecision(user.balance_fiat, 3) ?? "-/-"}&nbsp;
                </Typography.Text>
                <Image src={eurIcon} preview={false} width={18} />
              </Row>
            </Row>
            <Row justify="space-between">
              <Row align="middle">
                <Typography.Text>USDT (TRC 20)</Typography.Text>
                <PlusIcon
                  className={classes.plusIcon}
                  onClick={() => navigate(ROUTES.EXCHANGE)}
                />
              </Row>
              <Row align="middle">
                <Row align="middle">
                  <Typography.Text>
                    {formatNumberPrecision(user.balance_usdt, 3) ?? "-/-"}&nbsp;
                  </Typography.Text>
                  <Image src={usdtIcon} preview={false} width={18} />
                </Row>
                <Typography.Text>&nbsp;≈&nbsp;</Typography.Text>
                <Row align="middle">
                  <Typography.Text>
                    {formatNumberPrecision(user.balance_usdt / usdtCourse, 3) ??
                      "-/-"}
                    &nbsp;
                  </Typography.Text>
                  <Image src={eurIcon} preview={false} width={18} />
                </Row>
              </Row>
            </Row>
            <Row justify="space-between">
              <Row align="middle">
                <Typography.Text>USDT (ERC 20)</Typography.Text>
                <PlusIcon
                  className={classes.plusIcon}
                  onClick={() => navigate(ROUTES.EXCHANGE)}
                />
              </Row>
              <Row align="middle">
                <Row align="middle">
                  <Typography.Text>
                    {formatNumberPrecision(user.balance_usdt_eth, 2) ?? "-/-"}
                    &nbsp;
                  </Typography.Text>
                  <Image src={ethIcon} preview={false} width={18} />
                </Row>
                <Typography.Text>&nbsp;≈&nbsp;</Typography.Text>
                <Row align="middle">
                  <Typography.Text>
                    {formatNumberPrecision(
                      user.balance_usdt_eth / ethCourse,
                      2
                    ) ?? "-/-"}
                    &nbsp;
                  </Typography.Text>
                  <Image src={eurIcon} preview={false} width={18} />
                </Row>
              </Row>
            </Row>
          </Space>
        </div>
        <div className={classes.sideBarContainer}>
          <Space className="w100" direction="vertical">
            <Typography.Title level={5} className="text-center">
              CRYPTO SERVICES
            </Typography.Title>
            {cryptoServicesLinks.map((link) => (
              <Button
                className={classes.menuBtn}
                onClick={() => navigate(link.route)}
                icon={link.icon}
                key={link.key}
                type="text"
                block
              >
                <Typography.Paragraph style={{ marginBottom: 0 }} ellipsis>
                  {link.label}
                </Typography.Paragraph>
              </Button>
            ))}
          </Space>
          {breakpoints.md && (
            <React.Fragment>
              <Divider />
              <Button onClick={() => persistor.purge()} type="ghost" block>
                Log out
              </Button>
            </React.Fragment>
          )}
        </div>
      </Space>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  usdtCourse: getTrade(state).usdt.course,
  ethCourse: getTrade(state).eth.course,
});
export default connect(mapStateToProps)(SideBar);
