export const LogoShort = () => (
	<svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="13.2" width="3.2" height="6.8" rx="1.6" fill="url(#paint0_linear_818_1156)"/>
		<rect x="19.6001" y="6" width="3.6" height="6.8" rx="1.8" fill="url(#paint1_linear_818_1156)"/>
		<rect x="26" y="12.4" width="3.6" height="6.8" rx="1.8" fill="#04D6BD"/>
		<rect x="12.8" y="25.2" width="3.6" height="6.8" rx="1.8" fill="#04D6BD"/>
		<rect y="12.4" width="3.6" height="6.8" rx="1.8" fill="#04D6BD"/>
		<rect x="6.3999" y="19.2" width="3.6" height="6.8" rx="1.8" fill="url(#paint2_linear_818_1156)"/>
		<rect x="6.3999" y="6" width="3.6" height="11.2" rx="1.8" fill="url(#paint3_linear_818_1156)"/>
		<rect x="12.8" y="8.79999" width="3.6" height="14.4" rx="1.8" fill="#04D6BD"/>
		<rect x="19.6001" y="14.8" width="3.6" height="11.2" rx="1.8" fill="url(#paint4_linear_818_1156)"/>
		<defs>
			<linearGradient id="paint0_linear_818_1156" x1="14.8" y1="0" x2="14.8" y2="6.8" gradientUnits="userSpaceOnUse">
				<stop stopColor="#8A01D7"/>
				<stop offset="1" stopColor="#0381A6"/>
			</linearGradient>
			<linearGradient id="paint1_linear_818_1156" x1="21.4001" y1="6" x2="21.4001" y2="12.8" gradientUnits="userSpaceOnUse">
				<stop stopColor="#8A01D7"/>
				<stop offset="1" stopColor="#0381A6"/>
			</linearGradient>
			<linearGradient id="paint2_linear_818_1156" x1="8.1999" y1="19.2" x2="8.1999" y2="26" gradientUnits="userSpaceOnUse">
				<stop stopColor="#8A01D7"/>
				<stop offset="1" stopColor="#0381A6"/>
			</linearGradient>
			<linearGradient id="paint3_linear_818_1156" x1="8.1999" y1="6" x2="8.1999" y2="17.2" gradientUnits="userSpaceOnUse">
				<stop stopColor="#8A01D7"/>
				<stop offset="1" stopColor="#0381A6"/>
			</linearGradient>
			<linearGradient id="paint4_linear_818_1156" x1="21.4001" y1="14.8" x2="21.4001" y2="26" gradientUnits="userSpaceOnUse">
				<stop stopColor="#8A01D7"/>
				<stop offset="1" stopColor="#0381A6"/>
			</linearGradient>
		</defs>
	</svg>

)
