import React, {useCallback, useMemo, useState} from 'react';
import Container from "../../components/Container/Container";
import {
	Alert,
	Avatar,
	Button,
	Col,
	DatePicker,
	Descriptions,
	Form,
	Grid,
	Input,
	Modal,
	Row,
	Space,
	Typography
} from "antd";
import {CameraOutlined, UserOutlined} from "@ant-design/icons";
import {resetPassword, updateProfile, verifyAccount as submitVerification} from "../../store/actions/user";
import {FIELDS_SCHEME, GENERAL_SCHEME, validateMessages} from "../../schemas";
import {getUser} from "../../store/selectors";
import {connect, useDispatch} from "react-redux";
import {AML_STATUS, VERIFICATION_STATUS} from "../../utils/constants";
import dayjs from "dayjs";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../components/AppRouter/AppRouter";
import ProfileAvatar from "./ProfileAvatar";

const minDate = moment().subtract(100, 'years').toDate();
const maxDate = moment().subtract(18, 'years').subtract(1, 'day').toDate();

const Profile = ({user}) => {
	const [verifModalOpen, setVerifModalOpen] = useState(false);
	const [changePassModalOpen, setChangePassModalOpen] = useState(false);

	const [form] = Form.useForm();
	const breakpoints = Grid.useBreakpoint();
	const [passForm] = Form.useForm();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const verifyAccount = useCallback(() => {
		setVerifModalOpen(true);
		dispatch(submitVerification());
	}, []);

	const renderVerificationAlert = useCallback(() => {
	    switch (user.confirm.sumsub_status) {
		    case VERIFICATION_STATUS.IN_PROGRESS: {
				return <Alert closable message="Verification is in progress" type="info" showIcon />
		    }
		    case VERIFICATION_STATUS.REJECTED: {
			    return <Alert closable message="Verification was rejected. Try again" action={
				    <Button size="small" onClick={verifyAccount} type="text">
					    Verify account
				    </Button>
			    } type="error" showIcon />
		    }
		    case VERIFICATION_STATUS.NO_VERIFY: {
			    return <Alert closable message="Verify your account" action={
				    <Button size="small" onClick={verifyAccount} type="text">
					    Verify account
				    </Button>
			    } type="warning" showIcon />
		    }
		    default: {
				return undefined;
		    }
	    }
	}, [user.confirm.sumsub_status]);
	const renderAMLAlert = useCallback(() => {
	    switch (user.confirm.aml_status) {
		    case AML_STATUS.NOT_CHECKED: {
				return <Alert message="You need to fill out a user questionnaire" action={
					<Button size="small" onClick={() => navigate(ROUTES.SURVEY)} type="text">
						Fill out the form
					</Button>
				} type="warning" closable showIcon />
		    }
		    case AML_STATUS.REJECTED: {
			    return <Alert closable message="Your questionnaire was rejected" type="error" showIcon />
		    }
		    case AML_STATUS.MANUAL: {
			    return <Alert closable message="Your questionnaire is under manual review" type="info" showIcon />
		    }
		    default: {
				return undefined;
		    }
	    }
	}, [user.confirm.aml_status]);

	const defaultFormValues = useMemo(() => {
	    return {
		    first_name: user.meta?.first_name,
		    last_name: user.meta?.last_name,
		    profession: user.meta?.profession,
		    occupation: user.meta?.occupation,
		    date_of_birth: user.meta?.date_of_birth && dayjs(user.meta?.date_of_birth, 'DD.MM.YYYY'),
		    actual_residential_address: user.meta?.actual_residential_address,
	    }
	}, [user]);

	const onFormSubmitSuccess = useCallback((formData) => {
		dispatch(updateProfile(formData)).then(() => form.resetFields());
	}, []);

	const onPassFormSubmitSuccess = useCallback((formData) => {
		resetPassword(formData.password).then(() => setChangePassModalOpen(false));
	}, []);

	return (
		<Container>
			<Space className="w100" direction="vertical" style={{ marginBottom: 20 }}>
				{renderVerificationAlert()}
				{renderAMLAlert()}
			</Space>

			<Row justify="space-between" align="middle" style={{marginBottom: 20}} size="large">
				<Typography.Title level={breakpoints.md ? 1 : 3} style={{marginBottom: 0}}>Profile</Typography.Title>
			</Row>
			<Space className="w100" direction="vertical" size="large">
				<Space size="large">
					<ProfileAvatar/>
				</Space>
				<Row>
					<Col xs={24} md={12}>
						<Descriptions layout="vertical">
							<Descriptions.Item label="Phone number">{user.meta.mobile_number}</Descriptions.Item>
							<Descriptions.Item label="Email">{user.meta.email_address}</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>

				<Form initialValues={defaultFormValues} validateMessages={validateMessages} labelCol={{span: 24}} layout="horizontal" form={form} size="large" onFinish={onFormSubmitSuccess}
				      labelWrap>
					<Row gutter={[20, 0]} wrap>
						<Col xs={24} md={12}>
							<Form.Item label="Name" rules={[...FIELDS_SCHEME.name, ...GENERAL_SCHEME.required]} name="first_name">
								<Input placeholder="Your first name"/>
							</Form.Item>
							<Form.Item label="Surname" rules={[...FIELDS_SCHEME.name, ...GENERAL_SCHEME.required]} name="last_name">
								<Input placeholder="Your last name"/>
							</Form.Item>
							<Form.Item label="Address" rules={[...FIELDS_SCHEME.address, ...GENERAL_SCHEME.required]} name="actual_residential_address">
								<Input placeholder="Your address"/>
							</Form.Item>
						</Col>
						<Col xs={24} md={12}>
							<Form.Item label="Profession" rules={GENERAL_SCHEME.alphaSpace} name="profession">
								<Input placeholder="Your profession"/>
							</Form.Item>
							<Form.Item label="Occupation" rules={GENERAL_SCHEME.alphaSpace} name="occupation">
								<Input placeholder="Your occupation"/>
							</Form.Item>
							<Form.Item label="Date of birth" rules={GENERAL_SCHEME.required} name="date_of_birth">
								<DatePicker disabledDate={(current) => current && (current < minDate || current > maxDate)}
								            showToday={false} className="w100" placeholder="Your date of birth" inputReadOnly/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
				<Space direction="vertical" align="center" size="large" className="w100">
					<Button type="primary" onClick={form.submit} size="large">SAVE</Button>
					<Typography.Link onClick={() => setChangePassModalOpen(true)}>Change password</Typography.Link>
				</Space>

			</Space>

			<Modal title="VERIFICATION FORM" style={{top: 20}} open={verifModalOpen} footer={[]}
			       onCancel={() => setVerifModalOpen(false)}>
				<div id="sumsub-websdk-container"></div>
			</Modal>

			<Modal onCancel={() => setChangePassModalOpen(false)} title="Change password" style={{ top: 20 }} open={changePassModalOpen} footer={[]}>
				<Form validateMessages={validateMessages} layout="vertical" form={passForm} size="large" onFinish={onPassFormSubmitSuccess}>
					<Form.Item label="Password" name="password" rules={[...FIELDS_SCHEME.password, ...GENERAL_SCHEME.required]}>
						<Input.Password maxLength={20} placeholder="********"/>
					</Form.Item>
					<Form.Item dependencies={['password']} label="Confirm password" name="confirmPassword" rules={[...[...FIELDS_SCHEME.password, ...GENERAL_SCHEME.required], ({getFieldValue}) => ({
						validator(_, value) {
							if (!value || getFieldValue('password') === value) {
								return Promise.resolve();
							}
							return Promise.reject(new Error('Passwords don\'t match'));
						},
					})]}>
						<Input.Password maxLength={20} placeholder="********"/>
					</Form.Item>
					<Row justify="center">
						<Button onClick={passForm.submit}>Change password</Button>
					</Row>
				</Form>
			</Modal>
		</Container>
	);
};
const mapStateToProps = state => ({
	user: getUser(state),
})
export default connect(mapStateToProps)(Profile);
