import React from 'react';
import {Layout, Row, Space, Typography} from "antd";
import classes from "./Footer.module.scss";
import policy from "../../assets/privacy_policy.pdf";
import terms from "../../assets/terms.pdf";
import refund from "../../assets/refund_policy.pdf";
import aml from "../../assets/aml_policy.pdf";

const Footer = () => {
	return (
		<Layout.Footer className={classes.container}>
			<Row justify="center">
				<Space direction="vertical" align="center">
					<Space split="|">
						<Typography.Link href={policy} download="privacy_policy.pdf">Privacy policy</Typography.Link>
						<Typography.Link href={aml} download="aml_policy.pdf">AML/KYC policy</Typography.Link>
						<Typography.Link href={terms} download="terms_and_conditions.pdf">Terms of service</Typography.Link>
						<Typography.Link href={refund} download="refund_return_policy.pdf">Refund and return policy</Typography.Link>
					</Space>
					<Typography.Text>KRYPTOLITE | UAB J. SAVICKIO G. 4-7, LT-01108 VILNIUS, LITHUANIA</Typography.Text>
				</Space>

			</Row>
		</Layout.Footer>
	);
};

export default Footer;
