export const ENDPOINTS = Object.freeze({
	USER: {
		LOGIN: '/login',
		REGISTER: '/reg',
		GET_TOKEN: '/api/v2/user/sumsubtoken',
		INFO: '/user/user_info',
		ACCOUNT_INFO: '/api/v2/user/account_info',
		UPDATE_ACCOUNT_INFO: '/api/v2/user/set_account_info',
		TRADE_INFO: '/api/v2/info/trade_info',
		TRANSACTION_HISTORY: '/api/v2/user/transaction',
		BUY_CRYPTO: '/api/v2/buy',
		SELL_CRYPTO: '/api/v2/sell',
		SEND_CRYPTO: '/api/v2/send',
		SEND_CONTACT_FORM: '/api/v2/info/help',
		RESET_PASSWORD: '/api/v2/user/resetpass',
		UPDATE_PHOTO: '/api/v2/user/avatar',
		SEND_CONFIRM_CODE: '/confirm/send_confirm',
		CHECK_CONFIRM_CODE: '/confirm/get_confirm',
	}
});

export const NEW_ENDPOINTS = Object.freeze({
	AUTH: {
		LOGIN: '/login',
		REG: '/reg',
		RESET_PASS: '/reset',
		RESET_PASS_CONFIRM: '/reset_confirm'
	},
	USER: {
		INFO: '/user/user_info',
		RESET_PASSWORD: '/user/reset_pass',
		ACCOUNT_INFO: '/user/account_info',
		AML_QUESTIONS: '/user/aml_questions',
		USER_AML_QUESTIONS: '/user/aml_user',
		SUMSUB_TOKEN: '/user/sumsub_token',
		AML_SEND: '/user/aml_user',
		AML_GET: '/user/aml_questions',
		AVATAR: '/user/account_avatar',
	},
	INFO: {
		TRADE: '/info/trade',
		HELP: '/info/help',
		CHECKTRC: '/info/address_info'
	},
	ORDER: {
		CREATE: '/order/create',
		ORDER: '/order',
		ESTIMATE: '/order/estimate'
	},
	CONFIRM: {
		SEND: '/confirm/send_confirm',
		GET: '/confirm/get_confirm',
	},
});
