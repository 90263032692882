import React, {forwardRef, useCallback} from 'react';
import {Button, Checkbox, Col, DatePicker, Divider, Form, Input, InputNumber, Radio, Row, Select, Space} from "antd";
import {FIELDS_SCHEME, GENERAL_SCHEME, validateMessages} from "../../../schemas";
import moment from "moment/moment";
import classes from "../Survey.module.scss";
import {IS_DEV} from "../../../utils/constants";
import {professions} from "../data/professions";

const defaultInitialValues = {
    accountPurpose: 'Salary',
    finInsName: "test",
    incorporationCountry: "RU",
    wasInvolved: false,
    profession: "11",
    wasConvicted: false,
    wasDenied: false,
    hasObligations: false,
    hasDebtsOrLoans: "0",
    debtOrLoanAmount: 0,
}

const AddInfoSection = forwardRef(({initialValues, countries, footer, goBackStep,formDataInit, resetStep, ...props}, ref) => {
    const [form] = Form.useForm();
    const wasInvolved = Form.useWatch('wasInvolved', form);
    const wasConvicted = Form.useWatch('wasConvicted', form);
    const wasDenied = Form.useWatch('wasDenied', form);
    const hasDebtsOrLoans = Form.useWatch('hasDebtsOrLoans', form);
    const resetFields = () => form.resetFields();
    const resetForm = () => {
        resetFields();
        resetStep()
    }

    return (
        <Form ref={ref}  initialValues={formDataInit ? formDataInit : initialValues ? initialValues : IS_DEV && defaultInitialValues} validateMessages={validateMessages} layout="vertical" size="large" form={form} validateTrigger="onChange" name="step5">
            <Form.Item label="What is the purpose of opening the account?" rules={GENERAL_SCHEME.required} name="accountPurpose">
                <Select placeholder="What is the purpose of opening the account?" options={[
                    { value: 'Salary', label: 'Salary' },
                    { value: 'Self-Employed', label: 'Self-Employed' },
                    { value: 'Social benefits / pension', label: 'Social benefits / pension' },
                    { value: 'Gifts', label: 'Gifts' },
                    { value: 'Inheritance', label: 'Inheritance' },
                    { value: 'Business ownership income', label: 'Business ownership income' },
                    { value: 'Income from real-estate', label: 'Income from real-estate' },
                    { value: 'Loans', label: 'Loans' },
                    { value: 'Insurance payments', label: 'Insuransce payments' },
                    { value: 'Lottery or gambling winning', label: 'Lottery or gambling winning' },
                    { value: 'Other', label: 'Other' },
                ]} />
            </Form.Item>
            <Form.Item rules={[...GENERAL_SCHEME.required, ...GENERAL_SCHEME.alphaSpace]} label="Name of financial institution" name="finInsName">
                <Input placeholder="Name of financial institution"/>
            </Form.Item>
            <Form.Item rules={GENERAL_SCHEME.required} label="Country of incorporation" name="incorporationCountry">
                <Select placeholder="Country of incorporation" options={countries.map(country => ({
                    value: country.cca2,
                    label: <div  style={{ display: 'flex' }}>
                      <div
                        className={`fi fi-${country.cca2.toLowerCase()}`}
                        style={{ marginRight: 6 }}
                      />
                      <div>{country.name}</div>
                    </div>
                }))} filterOption={(input, option) =>
                    option.label.props.children[2].toLowerCase().startsWith(input.toLowerCase())
                } allowClear showSearch />
            </Form.Item>
            <Form.Item rules={GENERAL_SCHEME.required} label="Have you ever been involved in any money laundering or terrorist financing activities?" name="wasInvolved">
                <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>
            {wasInvolved === true && (
                <Form.Item rules={GENERAL_SCHEME.required} label="Explain:" name="wasInvolvedExplanation">
                    <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} autoClear/>
                </Form.Item>
            )}
            <Form.Item label="Profession information" rules={GENERAL_SCHEME.required} name="profession">
                <Select optionFilterProp="label" placeholder="Profession information" options={Object.entries(professions).map(([key, object]) => ({ value: key, label: object.name }))} showSearch />
            </Form.Item>
            <Form.Item rules={GENERAL_SCHEME.required} label="Have you ever been convicted of any financial crimes?" name="wasConvicted">
                <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>
            {wasConvicted === true && (
                <Form.Item rules={GENERAL_SCHEME.required} label="Explain:" name="wasConvictedExplanation">
                    <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} autoClear/>
                </Form.Item>
            )}
            <Form.Item rules={GENERAL_SCHEME.required} label="Have you ever been denied an account by any financial institution?" name="wasDenied">
                <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>
            {wasDenied === true && (
                <Form.Item rules={GENERAL_SCHEME.required} label="Explain:" name="wasDeniedExplanation">
                    <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} autoClear/>
                </Form.Item>
            )}
            <Form.Item rules={GENERAL_SCHEME.required} label="Do you have any outstanding debt or loan obligations?" name="hasDebtsOrLoans">
                <Radio.Group>
                    <Radio value={"1"}>Loan</Radio>
                    <Radio value={"2"}>Debt</Radio>
                    <Radio value={"0"}>No debts/loans</Radio>
                </Radio.Group>
            </Form.Item>
            {(hasDebtsOrLoans === "1" || hasDebtsOrLoans === "2") && (
                <Row>
                    <Col xs={24} md={12}>
                        <Form.Item rules={GENERAL_SCHEME.required} label="Amount:" name="debtOrLoanAmount">
                            <InputNumber className="w100" min={1} max={100000} addonAfter="€" />
                        </Form.Item>
                    </Col>
                </Row>
            )}

            {footer ? footer({ form }) : (
                <Row justify="end">
                    <Space wrap="wrap">
                        <Button type="text" onClick={resetForm}>
                            Reset
                        </Button>
                        {/*<Button onClick={}>*/}
                        {/*    Back*/}
                        {/*</Button>*/}
                        <Button onClick={goBackStep}>
                            Prev
                        </Button>
                        <Button onClick={form.submit}>
                            Next
                        </Button>
                    </Space>
                </Row>
            )}
        </Form>
    );
});

export default AddInfoSection;
