import React, { useEffect, useMemo } from "react";
import { fetchTransactionHistory } from "../../store/actions/user";
import { connect, useDispatch } from "react-redux";
import Container from "../../components/Container/Container";
import {
  Descriptions,
  Grid,
  Image,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { getUser } from "../../store/selectors";
import { TRANSACTION_STATUS, TRANSACTION_TYPE } from "../../utils/constants";
import classes from "./TransactionHistory.module.scss";
import moment from "moment";
import tronIcon from "../../assets/svg/tron-trx-logo.svg";
import { LoadingOutlined } from "@ant-design/icons";

function generateTag(status, type) {
  switch (status) {
    case TRANSACTION_STATUS.DONE: {
      return <Tag color="#73d13d">Completed</Tag>;
    }
    case TRANSACTION_STATUS.NEW: {
      return (
        <Tag>
          {[TRANSACTION_TYPE.SELL, TRANSACTION_TYPE.SEND].includes(type)
            ? "Awaiting payout queue"
            : "Awaiting your funds"}
        </Tag>
      );
    }
    case TRANSACTION_STATUS.PAID: {
      return (
        <Tag>
          {[TRANSACTION_TYPE.SELL, TRANSACTION_TYPE.SEND].includes(type)
            ? "Crypto transferred"
            : "Fiat received"}
        </Tag>
      );
    }
    case TRANSACTION_STATUS.WAIT_OUT_PAYMENT: {
      return (
        <Tag>
          {[TRANSACTION_TYPE.SELL, TRANSACTION_TYPE.SEND].includes(type)
            ? "Waiting for fiat transfer"
            : "Waiting for crypto transfer"}
        </Tag>
      );
    }
    case TRANSACTION_STATUS.CANCEL: {
      return <Tag color="#a8071a">Canceled</Tag>;
    }
    case TRANSACTION_STATUS.CHECKED: {
      return <Tag>Checking completed</Tag>;
    }
    case TRANSACTION_STATUS.ERROR_DONE: {
      return <Tag color="#f5222d">Error occured</Tag>;
    }
    default:
      return null;
  }
}

const TransactionHistory = ({ transactions }) => {
  const dispatch = useDispatch();
  const breakpoints = Grid.useBreakpoint();

  const columns = useMemo(() => {
    return [
      {
        key: "createdAt",
        dataIndex: "createdAt",
        title: "Date",
        responsive: ["md"],
        render: (value) => moment(value).format("MMMM Do YYYY, h:mm:ss a"),
      },
      {
        key: "type",
        title: "Type",
        dataIndex: "type",
        width: 90,
        filters: Object.keys(TRANSACTION_TYPE).map((t) => ({
          value: t,
          text: TRANSACTION_TYPE[t],
        })),
        onFilter: (value, record) => record.type.indexOf(value) === 0,
      },
      {
        key: "amount",
        dataIndex: "",
        title: "Amount",
        render: (_, record) => {
          const amount =
            record.type === TRANSACTION_TYPE.BUY
              ? record.sum_fiat
              : record.sum_crypto;
          return (
            <Typography.Text>
              {amount} {record.crypto}{" "}
              {record.network === "eth" ? "(ERC 20)" : "(TRC20)"}{" "}
              <Typography.Text className={classes.amountSecondaryText}>
                ({record.course} / {record.fee}%)
              </Typography.Text>
            </Typography.Text>
          );
        },
      },
      {
        key: "status",
        dataIndex: "status",
        title: "Status",
        responsive: ["md"],
        render: generateTag,
      },
    ];
  }, [breakpoints]);

  const dataSource = useMemo(() => {
    return transactions.map((t) => ({
      key: t.id,
      type: t.type.toUpperCase(),
      sum_fiat: t.sum_fiat,
      sum_crypto: t.sum_crypto,
      course: t.course,
      fee: t.fee,
      fee_trx: t.fee_trx,
      crypto: t.crypto,
      status: t.status,
      updatedAt: t.updatedAt,
      createdAt: t.createdAt,
      crypto_wallet_to: t.crypto_wallet_to,
      crypto_wallet_from: t.crypto_wallet_from,
      network: t.network,
    }));
  }, [transactions]);

  useEffect(() => {
    dispatch(fetchTransactionHistory());
  }, []);

  return (
    <Container>
      <Typography.Title level={breakpoints.md ? 1 : 3}>
        Transactions history
      </Typography.Title>

      <Table
        rowClassName={(record, index) => {
          if (!breakpoints.md) {
            switch (record.status) {
              case TRANSACTION_STATUS.DONE:
                return classes.completedTransaction;
              case TRANSACTION_STATUS.ERROR_DONE:
                return classes.errorTransaction;
            }
          }
        }}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <Descriptions layout="horizontal">
                <Descriptions.Item span={24} label="Transaction ID">
                  {record.key}
                </Descriptions.Item>
                {record.type === TRANSACTION_TYPE.RECEIVE && (
                  <Descriptions.Item span={24} label="Sender">
                    {record.crypto_wallet_from}
                  </Descriptions.Item>
                )}
                {record.type === TRANSACTION_TYPE.SEND && (
                  <Descriptions.Item span={24} label="Recipient">
                    {record.crypto_wallet_to}
                  </Descriptions.Item>
                )}
                <Descriptions.Item span={24} label="Created">
                  {moment(record.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                </Descriptions.Item>
                <Descriptions.Item span={24} label="Last update">
                  {moment(record.updatedAt).format("MMMM Do YYYY, h:mm:ss a")}
                </Descriptions.Item>
                {/*{record.fee_trx ? (*/}
                {/*	<Descriptions.Item span={24} label="TRON cost">*/}
                {/*		<Tag>*/}
                {/*			<Space align="middle">*/}
                {/*				<Typography.Text>{record.fee_trx}</Typography.Text>*/}
                {/*				<Image src={tronIcon} preview={false} width={18}/>*/}
                {/*			</Space>*/}
                {/*		</Tag>*/}
                {/*	</Descriptions.Item>*/}
                {/*) : (*/}
                {/*	<Descriptions.Item span={24} label="TRON cost">*/}
                {/*		<Tag>*/}
                {/*			<LoadingOutlined/>*/}
                {/*		</Tag>*/}
                {/*	</Descriptions.Item>*/}
                {/*)}*/}
                {!breakpoints.lg && (
                  <Descriptions.Item span={24} label="Status">
                    {generateTag(record.status, record.type)}
                  </Descriptions.Item>
                )}
              </Descriptions>
            );
          },
        }}
        dataSource={dataSource}
        columns={columns}
      />
    </Container>
  );
};
const mapStateToProps = (state) => ({
  transactions: getUser(state).transactions,
});
export default connect(mapStateToProps)(TransactionHistory);
