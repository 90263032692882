import React, { useCallback, useEffect, useMemo, useState } from "react";
import Container from "../../components/Container/Container";
import {
  Alert,
  Button,
  Form,
  Grid,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import CurrencySelector from "../../components/CurrencySelector/CurrencySelector";
import { getUser } from "../../store/selectors";
import { connect, useDispatch } from "react-redux";
import usdtCoin from "../../assets/svg/usdt_coin.svg";
import ethCoin from "../../assets/svg/eth_coin.svg";
import {
  AML_STATUS,
  CURRENCY,
  TRANSACTION_TYPE,
  VERIFICATION_STATUS,
} from "../../utils/constants";
import {
  calculateFees,
  checkValidTRC,
  getTradeInfo,
  sendCrypto,
} from "../../store/actions/trades";
import { ROUTES } from "../../components/AppRouter/AppRouter";
import { useNavigate } from "react-router-dom";
import { FIELDS_SCHEME, GENERAL_SCHEME, validateMessages } from "../../schemas";
import { isProfileFilled } from "../../utils/helpers";
import useTransaction from "../../hooks/useTransaction";

const sendCurrencies = [
  { value: CURRENCY.USDT, label: CURRENCY.USDT + " (TRC20)", src: usdtCoin },
  { value: CURRENCY.ETH, label: "USDT (ERC 20)", src: ethCoin },
];

const Send = ({ isUserVerified, user, userMeta }) => {
  const [tradeData, setTradeData] = useState({
    fee: null,
    course: null,
    min_sum: null,
    max_sum: null,
    fee_eur: null,
    fee_crypto: null,
  });

  const [form] = Form.useForm();
  const payCurrency = Form.useWatch("currency", form);
  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const breakpoints = Grid.useBreakpoint();
  const { onFormSubmitSuccess, loading } = useTransaction(
    user,
    async (currency, sum, to_wallet) => {
      dispatch(sendCrypto(currency, sum, to_wallet));
    },
    form
  );
  console.log("payCurrency", payCurrency);
  const onFinish = useCallback(
    async (formData) => {
      if (payCurrency !== CURRENCY.ETH) {
        const res = await checkValidTRC(formData.wallet);

        if (res?.valid_address) {
          onFormSubmitSuccess(
            TRANSACTION_TYPE.SEND,
            formData.currency,
            formData.amount,
            { wallet: formData.wallet }
          );
        }
      } else {
        onFormSubmitSuccess(
          TRANSACTION_TYPE.SEND,
          formData.currency,
          formData.amount,
          { wallet: formData.wallet }
        );
      }
    },
    [onFormSubmitSuccess]
  );

  const isProfileApproved = useMemo(() => {
    return user?.confirm?.aml_status === AML_STATUS.APPROVED;
  }, [user]);

  const renderAMLAlert = useCallback(() => {
    switch (user.confirm.aml_status) {
      case AML_STATUS.NOT_CHECKED: {
        return (
          <Alert
            message="You need to fill out a user questionnaire"
            action={
              <Button
                size="small"
                onClick={() => navigate(ROUTES.SURVEY)}
                type="text"
              >
                Fill out the form
              </Button>
            }
            type="warning"
            closable
            showIcon
          />
        );
      }
      case AML_STATUS.REJECTED: {
        return (
          <Alert
            closable
            message="Your questionnaire was rejected"
            type="error"
            showIcon
          />
        );
      }
      case AML_STATUS.MANUAL: {
        return (
          <Alert
            closable
            message="Your questionnaire is under manual review"
            type="info"
            showIcon
          />
        );
      }
      default: {
        return undefined;
      }
    }
  }, [user.confirm.aml_status]);

  useEffect(() => {
    dispatch(getTradeInfo(TRANSACTION_TYPE.SEND, { toState: false })).then(
      (resp) => {
        if (payCurrency) {
          setTradeData(resp[payCurrency.toLowerCase()]);
          calculateFees(TRANSACTION_TYPE.SEND, payCurrency, 1, false).then(
            ({ fee_eur, fee_crypto }) => {
              setTradeData((prevState) => ({
                ...prevState,
                fee_eur,
                fee_crypto,
              }));
            }
          );
        }
      }
    );

    if (!isUserVerified || !isProfileApproved) modal.warning(modalConfig);
  }, [payCurrency]);

  const modalConfig = useMemo(
    () => ({
      keyboard: false,
      width: 700,
      centered: true,
      maskClosable: false,
      closable: false,
      footer: [
        <Row justify="end">
          <Button
            key="submit"
            onClick={() => {
              navigate(ROUTES.PROFILE);
            }}
            type="text"
          >
            OK
          </Button>
        </Row>,
      ],
      title: isUserVerified
        ? "Please fill in the information in your account"
        : "Confirm your account",
      content: isUserVerified
        ? "In order to proceed further, it's required to complete your profile information. Please fill out all the necessary fields in your profile."
        : "Once your account has been verified, you'll be able to buy and sell " +
          "cryptocurrency with confidence, knowing that your account has been fully vetted and approved." +
          " If you have any questions or need assistance with the verification process, please don't hesitate to contact our support team for help.",
    }),
    [isProfileApproved, isUserVerified]
  );

  return (
    <Container>
      {contextHolder}
      <Typography.Title level={breakpoints.md ? 1 : 3}>
        Send crypto ({payCurrency === CURRENCY.USDT ? "TRC20" : "ERC20"})
      </Typography.Title>
      <Form
        validateMessages={validateMessages}
        disabled={!(isUserVerified && isProfileApproved)}
        validateTrigger="onChange"
        initialValues={{
          currency: sendCurrencies[0].value,
        }}
        form={form}
        size={breakpoints.md ? "large" : "small"}
        labelCol={{ span: 3 }}
        labelAlign="left"
        onFinish={onFinish}
        labelWrap
        style={{ marginBottom: 20 }}
      >
        <Form.Item rules={GENERAL_SCHEME.required} label="Pay" name="amount">
          <InputNumber
            className="w100"
            placeholder={`${tradeData.min_sum ?? "-/-"} ${
              sendCurrencies.find((c) => c.value === payCurrency)?.["label"]
            } - ${tradeData.max_sum ?? "-/-"} ${
              sendCurrencies.find((c) => c.value === payCurrency)?.["label"]
            }`}
            addonAfter={
              <CurrencySelector arr={sendCurrencies} name="currency" />
            }
          />
        </Form.Item>
        <Form.Item
          rules={[...FIELDS_SCHEME.wallet, ...GENERAL_SCHEME.required]}
          label="To wallet"
          name="wallet"
        >
          <Input maxLength={42} placeholder="Direction wallet" />
        </Form.Item>
        <Typography>
          Commission: {tradeData.fee_crypto}{" "}
          {sendCurrencies.find((c) => c.value === payCurrency)?.["label"]} +{" "}
          {tradeData.fee}%
        </Typography>
        <Row justify="center">
          <Button loading={loading} type="primary" onClick={form.submit}>
            Send {payCurrency} (
            {payCurrency === CURRENCY.USDT ? "TRC20" : "ERC20"})
          </Button>
        </Row>
      </Form>
      {renderAMLAlert()}
    </Container>
  );
};
const mapStateToProps = (state) => ({
  isUserVerified:
    getUser(state).confirm.sumsub_status === VERIFICATION_STATUS.VERIFIED,
  userMeta: getUser(state).meta,
  user: getUser(state),
});
export default connect(mapStateToProps)(Send);
