export const validateMessages = {
	required: "Required field",
	type: 'Field has an incorrect data type',
};

export const GENERAL_SCHEME = {
	required: [
		{
			required: true,
			message: "Required field",
		},
	],
	alpha: [
		{
			pattern: /^[a-zA-Z]+$/,
			message: 'The name can only contain Latin letters (a-z)'
		},
	],
	alphaSpace: [
		{
			pattern: /^[a-zA-Z\s]+$/,
			message: 'Must contain only letters and spaces'
		},
	],
	numeric: [
		{
			pattern: /^[0-9]+$/,
			message: 'Must contain only numbers'
		},
	],
	numericSpace: [
		{
			pattern: /^[0-9\s]+$/,
			message: 'Must contain only numbers and spaces'
		},
	],
	alphaNumeric: [
		{
			pattern: /^[a-zA-Z0-9]+$/,
			message: 'Must contain only letters and numbers'
		},
	],
	alphaNumericSpace: [
		{
			pattern: /^[a-zA-Z0-9\s]+$/,
			message: 'Must contain letters, numbers and spaces'
		},
	],
	alphaSymbol: [
		{
			pattern: /^[a-zA-Z!@#$%^&*()_+,\.\-\/:;<=>?@[\\\]^_`{|}~]+$/,
			message: 'Must contain only letters and symbols'
		},
	],
	numericSymbol: [
		{
			pattern: /^[0-9!@#$%^&*()_+,\.\-\/:;<=>?@[\\\]^_`{|}~]+$/,
			message: 'Must contain only numbers and symbols'
		},
	],
	alphaNumericSymbol: [
		{
			pattern: /^[a-zA-Z0-9!@#$%^&*()_+,\.\-\/:;<=>?@[\\\]^_`{|}~]+$/,
			message: 'Must contain letters, numbers and symbols'
		},
	],
	alphaSymbolSpace: [
		{
			pattern: /^[a-zA-Z!@#$%^&*()_+,\.\-\/:;<=>?@[\\\]^_`{|}~\s]+$/,
			message: 'Must contain only letters, symbols, and spaces'
		},
	],
	numericSymbolSpace: [
		{
			pattern: /^[0-9!@#$%^&*()_+,\.\-\/:;<=>?@[\\\]^_`{|}~\s]+$/,
			message: 'Must contain only numbers, symbols, and spaces'
		},
	],
	alphaNumericSymbolSpace: [
		{
			pattern: /^[a-zA-Z0-9!@#$%^&*()_+,\.\-\/:;<=>?@[\\\]^_`{|}~\s]+$/,
			message: 'Must contain letters, numbers, symbols, and spaces'
		},
	]
}

export const FIELDS_SCHEME = {
	name: [{
		min: 2,
		message: 'Name must be at least 2 characters',
	}, {
		max: 20,
		message: 'Name must not exceed 20 characters',
	}, ...GENERAL_SCHEME.alpha],
	surname: [{
		min: 2,
		message: 'Last name must be at least 2 characters',
	}, {
		max: 20,
		message: 'Last name must not exceed 20 characters',
	}, ...GENERAL_SCHEME.alpha],
	patronymic: [{
		min: 2,
		message: 'Middle name must be at least 2 characters',
	}, {
		max: 20,
		message: 'Middle name must not exceed 20 characters',
	}, ...GENERAL_SCHEME.alphaSpace],
	fullName: [{
		min: 2,
		message: 'Full name must be at least 2 characters',
	}, {
		max: 60,
		message: 'Full name must not exceed 60 characters',
	}, ...GENERAL_SCHEME.alphaSpace],
	email: [{
		pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		message: 'The email address must be valid',
	}],
	address: [{
		type: 'string',
		message: 'Value must be a string',
	}, {
		min: 2,
		message: 'Address must be at least 2 characters',
	}, {
		max: 70,
		message: 'Address must not exceed 70 characters',
	}, {
		pattern: /^[A-Za-z0-9\s&()/,.-]+$/,
		message: 'Address can only contain Latin letters (a-z), numbers (0-9), spaces and symbols (.,&//()-)',
	}],
	zipcode: [ {
		min: 2,
		message: 'Postal code must be at least 5 characters',
	}, {
		max: 20,
		message: 'Postal code must not exceed 15 characters',
	}, {
		pattern: /^[A-Za-z0-9\s-]+$/,
		message: 'Postal code can only contain Latin letters (a-z), numbers (0-9), spaces and hyphens',
	}],
	city: [{
		type: 'string',
		message: 'Value must be a string',
	}, {
		min: 2,
		message: 'City must be at least 2 characters',
	}, {
		max: 30,
		message: 'City must not exceed 30 characters',
	}, {
		pattern: /^[A-Za-z\s]+$/,
		message: 'City can only contain letters and spaces',
	}],

	phoneNumber: [{
		type: 'string',
		message: 'Value must be a string',
	}, {
		min: 5,
		message: 'Phone number must be at least 5 characters',
	}, {
		max: 15,
		message: 'Phone number must not exceed 15 characters',
	}, ...GENERAL_SCHEME.numeric],

	password: [{
		type: 'string',
		message: 'Value must be a string',
	}, {
		min: 8,
		message: 'Password must be at least 8 characters',
	}, {
		max: 20,
		message: 'Password must not exceed 20 characters',
	}, {
		pattern: /[A-Za-z]/,
		message: 'Password must contain at least one letter',
	},
		{
			pattern: /[0-9]/,
			message: 'Password must contain at least one digit',
		},],

	wallet: [{
		type: 'string',
		message: 'Value must be a string',
	}, {
		pattern: /^[A-Za-z0-9]{30,42}$/,
		message: 'Invalid wallet format',
	}],

	iban: [{
		type: 'string',
		message: 'Value must be a string',
	}, {
		max: 34,
		message: 'IBAN must not exceed 34 characters',
	}, {
		pattern: /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/,
		message: 'Invalid IBAN format',
	}],
}
