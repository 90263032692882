import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Form, Grid, Input, message, Row, Select, Space, Typography} from "antd";
import {useDispatch} from "react-redux";
import Container from "../../components/Container/Container";
import classes from "../SignUp/SignUp.module.scss";
import privacy from "../../assets/privacy_policy.pdf";
import {ROUTES} from "../../components/AppRouter/AppRouter";
import {useNavigate} from "react-router-dom";
import {signUp} from "./actions";
import {FIELDS_SCHEME, GENERAL_SCHEME, validateMessages} from "../../schemas";
import {IS_DEV} from "../../utils/constants";
import {fetchCountries} from "../../utils/fetchCountries";
import { parsePhoneNumberFromString, isValidPhoneNumber } from 'libphonenumber-js';
import "/node_modules/flag-icons/css/flag-icons.min.css";

const SignUp = () => {
	const [loading, setLoading] = useState(false);
	const [countries, setCountries] = useState([]);
	const [formData, setFormData] = useState({});
	const [currentStep, setCurrentStep] = useState(0);

	const [regForm] = Form.useForm();
	const [addressForm] = Form.useForm();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const breakpoints = Grid.useBreakpoint();

	const onRegFormFinish = useCallback((data) => {
		setFormData(data);
		setCurrentStep(1);
	}, []);

	const validatePhoneNumber = (phoneNumber) => {
		const phoneNumberObject = parsePhoneNumberFromString(`+${phoneNumber}`);
		if (!phoneNumberObject) {
			return false;
		}
		return phoneNumberObject.isValid();
	};

	const onAddressFormFinish = useCallback((data) => {
		const form = { ...formData, ...data };
		setLoading(true);
		dispatch(signUp(form))
			.then(() => navigate(ROUTES.CONFIRM_EMAIL))
			.finally(() => setLoading(false));
	}, [formData]);

	useEffect(() => {
		setCountries(fetchCountries())
	}, []);

	return (
		<Container className={classes.container}>
			<Typography.Title level={breakpoints.md ? 1 : 3}>Registration</Typography.Title>
			<Space direction="vertical" align="center">
				<Typography.Text  className="color-white">Already have an account?&nbsp;
					<Typography.Link onClick={() => navigate(ROUTES.SIGN_IN)}>Log in</Typography.Link></Typography.Text>
				{/*<Typography.Link onClick={() => navigate(ROUTES.SIGN_UP_BUSINESS)} style={{ marginBottom: 40 }}>Create account for business</Typography.Link>*/}
			</Space>

			{currentStep === 1 ? (
				<Form disabled={loading} validateMessages={validateMessages} style={{ maxWidth: 410 }} className="w100" layout="vertical" form={addressForm} size="large" onFinish={onAddressFormFinish}>
					{/* <Form.Item label="Country" rules={GENERAL_SCHEME.required} name="country">
						<Select placeholder="Select country" options={countries.map(country => ({
							value: country.cca2,
							label: <div className={classes.countryOptionRow}> 
								{country.name}
							</div>
						}))} filterOption={(input, option) =>
							option.label.props.children[2].toLowerCase().startsWith(input.toLowerCase())
						} allowClear showSearch />
					</Form.Item> */}
					<Form.Item label="Country" rules={GENERAL_SCHEME.required} name="country">
					<Select
						showSearch
						placeholder="Select country"
						>
						{countries
							.map((country, idx) => (
							<Select.Option
								label={country.name}
								value={country.cca2}
								key={country.cca2}>
								<div  style={{ display: 'flex' }}>
								<div
									className={`fi fi-${country.cca2.toLowerCase()}`}
									style={{ marginRight: 6 }}
								/>
								<div>{country.name}</div>
								</div>
							</Select.Option>
							))}
        </Select>
					</Form.Item>
					<Form.Item label="City" name="city" rules={[...GENERAL_SCHEME.alphaSymbolSpace, ...GENERAL_SCHEME.required, ...FIELDS_SCHEME.city]}>
						<Input placeholder="City" />
					</Form.Item>
					<Form.Item label="Postal code"  name="zip_code" rules={[...GENERAL_SCHEME.alphaNumericSymbolSpace, ...GENERAL_SCHEME.required, ...FIELDS_SCHEME.zipcode]}>
						<Input maxLength={31} placeholder="Postal code" />
					</Form.Item>
					<Space direction="vertical" align="center" style={{ width: '100%', textAlign: 'center' }}>
						<Space>
							<Button loading={loading} style={{ maxWidth: 200 }} onClick={() => setCurrentStep(0)} size="large">Back</Button>
							<Button loading={loading} style={{ maxWidth: 200 }} onClick={regForm.submit} size="large">Sign up</Button>
						</Space>
						<Typography.Text  className="text-center">By continuing you accept our standard
							<Typography.Link> terms & conditions</Typography.Link>&nbsp;and&nbsp;
							<Typography.Link download="privacy.pdf" href={privacy}>our privacy policy.</Typography.Link></Typography.Text>
					</Space>
				</Form>
			) : (
				<Form disabled={loading} validateMessages={validateMessages} initialValues={IS_DEV && {
					email: 'test15@gmail.com',
					phone: '123333223',
					password: 'Qwerty12345',
					confirmPassword: 'Qwerty12345',
				}} style={{ maxWidth: 410 }} className="w100" layout="vertical" form={regForm} size="large" onFinish={onRegFormFinish}>
					<Form.Item label="Email" name="email" rules={[...FIELDS_SCHEME.email, ...GENERAL_SCHEME.required]}>
						<Input placeholder="email@example.com" />
					</Form.Item>
					<Form.Item label="Phone number" name="phone" rules={[
						...GENERAL_SCHEME.numeric,
						...GENERAL_SCHEME.required,
						() => ({
							validator(_, value) {
								if (value && !validatePhoneNumber(value)) {
									return Promise.reject(new Error('The phone number is not valid.'));
								}
								return Promise.resolve();
							},
						}),
					]}>
						<Input placeholder="1234567890" />
					</Form.Item>
					<Form.Item label="New password" name="password" rules={[...FIELDS_SCHEME.password, ...GENERAL_SCHEME.required]}>
						<Input.Password placeholder="********"/>
					</Form.Item>
					<Form.Item dependencies={['password']} label="Confirm new password" name="confirmPassword" rules={[...[...FIELDS_SCHEME.password, ...GENERAL_SCHEME.required], ({getFieldValue}) => ({
						validator(_, value) {
							if (!value || getFieldValue('password') === value) {
								return Promise.resolve();
							}
							return Promise.reject(new Error('Passwords don\'t match'));
						},
					})]}>
						<Input.Password placeholder="********"/>
					</Form.Item>
					<Row justify="center">
						<Button loading={loading} onClick={regForm.submit} type="primary" size="large">Continue</Button>
					</Row>
				</Form>
			)}
		</Container>
	);
};

export default SignUp;
