export const fetchCountries = () => {
  return [
    { name: 'Austria', cca2: 'AT' },
    { name: 'Belgium', cca2: 'BE' },
    { name: 'Bulgaria', cca2: 'BG' },
    { name: 'Croatia', cca2: 'HR' },
    { name: 'Cyprus', cca2: 'CY' },
    { name: 'CzechRepublic', cca2: 'CZ' },
    { name: 'Denmark', cca2: 'DK' },
    { name: 'Estonia', cca2: 'EE' },
    { name: 'Finland', cca2: 'FI' },
    { name: 'France', cca2: 'FR' },
    { name: 'Germany', cca2: 'DE' },
    { name: 'Greece', cca2: 'GR' },
    { name: 'Hungary', cca2: 'HU' },
    { name: 'Ireland', cca2: 'IE' },
    { name: 'Italy', cca2: 'IT' },
    { name: 'Latvia', cca2: 'LV' },
    { name: 'Lithuania', cca2: 'LT' },
    { name: 'Luxembourg', cca2: 'LU' },
    { name: 'Malta', cca2: 'MT' },
    { name: 'Poland', cca2: 'PL' },
    { name: 'Portugal', cca2: 'PT' },
    { name: 'Romania', cca2: 'RO' },
    { name: 'Slovakia', cca2: 'SK' },
    { name: 'Slovenia', cca2: 'SI' },
    { name: 'Spain', cca2: 'ES' },
    { name: 'Sweden', cca2: 'SE' },
    { name: 'Switzerland', cca2: 'CH' },
  ]
}
