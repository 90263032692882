import {PURGE} from "redux-persist/es/constants";

const initialState = {
	usdt: {
		fee: null,
		course: null,
		min_sum: null,
		max_sum: null,
	},
	eth: {
		fee: null,
		course: null,
		min_sum: null,
		max_sum: null,
	},
}

const tradeReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'Trade/SET_USDT': {
			return {...state, usdt: {...state.usdt, ...action.payload}};
		}

		case 'Trade/SET_ETH': {
			return {...state, eth: {...state.eth, ...action.payload}};
		}

		case PURGE: {
			return initialState;
		}

		default:
			return state;
	}
};

export default tradeReducer;
