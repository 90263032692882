import React, {useEffect, useMemo, useState} from 'react';
import Container from "../../components/Container/Container";
import {Button, Col, Divider, Grid, Image, Row, Select, Space, Typography} from "antd";
import classes from './Home.module.scss';
import {CURRENCY, directions} from "../../utils/constants";
import rateDiagram from "../../assets/svg/graphic.svg";
import {ROUTES} from "../../components/AppRouter/AppRouter";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {getTrade} from "../../store/selectors";
import {formatNumberPrecision} from "../../utils/helpers";

const Home = ({usdtCourse, ethCourse}) => {
	const [activeDirectionId, setActiveDirectionId] = useState(7);

	const navigate = useNavigate();
	const breakpoints = Grid.useBreakpoint();

	const activeDirectionObj = useMemo(() => {
		if (activeDirectionId) return directions.find(d => d.id === activeDirectionId);
	}, [activeDirectionId]);

	return (
		<Container>
			<Typography.Title level={breakpoints.md ? 1 : 3}>Crypto Services</Typography.Title>
			<Typography.Paragraph>Online service CRYPTOLITE.tech allows to buy, sell and exchange various types of
				cryptocurrencies - Bitcoin, Litecoin,
				Ethereum, DASH, Monero, Z-Cash, Decred and other. We accept international currencies. Low rates, fast
				processing of
				applications, technical support.</Typography.Paragraph>
			<Divider orientation="left">Exchange</Divider>
			<Space className="w100" direction="vertical" align={breakpoints.md ? 'start' : 'center'} size="large">
				<Space align="center" size="middle">
					<Typography.Text>Currency</Typography.Text>
					<Select style={{width: 160}} value={activeDirectionId} onChange={setActiveDirectionId}
					        options={directions.map(direction => ({
						        value: direction.id,
						        label: direction.name,
						        disabled: direction.disabled
					        }))}
					/>
				</Space>
				<div className={classes.rateContainer}>
					<Space className="w100" direction="vertical">
						<Typography.Title level={5}>MARKET RATE</Typography.Title>
						<Row gutter={8} justify="space-between">
							<Col xs={24} md={12}>
								<Typography.Text>1 EUR = {formatNumberPrecision(activeDirectionId === 7 ? usdtCourse : ethCourse, 4) ?? '-/-'} {activeDirectionObj.name}</Typography.Text>
							</Col>
							<Col xs={24} md={12}>
								<Image preview={false} src={rateDiagram}/>
							</Col>
						</Row>
					</Space>
				</div>
				<Typography.Paragraph className={!breakpoints.md && 'text-center'}>You can buy and sell cryptocurrencies
					fast and secure at a fixed rate</Typography.Paragraph>
				<Space>
					<Button onClick={() => navigate(ROUTES.EXCHANGE)} size="large" type="primary">BUY / SELL</Button>
				</Space>
			</Space>
		</Container>
	);
};
const mapStateToProps = state => ({
	usdtCourse: getTrade(state).usdt.course,
	ethCourse: getTrade(state).eth.course,
})
export default connect(mapStateToProps)(Home);
