import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Grid,
  Image,
  Input,
  InputNumber, Modal,
  QRCode,
  Row,
  Space,
  Typography
} from "antd";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Container from "../../components/Container/Container";
import { FIELDS_SCHEME, GENERAL_SCHEME, validateMessages } from "../../schemas";
import { getUser } from "../../store/selectors";
import eurIcon from '../../assets/svg/euro_coin.svg';
import siteLogo from '../../assets/svg/logo-mobile.svg';
import { AML_STATUS, TRANSACTION_TYPE, VERIFICATION_STATUS } from "../../utils/constants";
import { calculateFees, getTradeInfo, sendFiat } from "../../store/actions/trades";
import { ROUTES } from "../../components/AppRouter/AppRouter";
import * as modal from "@ant-design/icons/es/utils";

const FiatExchange = ({ isUserVerified, user }) => {
  const [loading, setLoading] = useState(false);
  const [tradeData, setTradeData] = useState({
    fee: null,
    course: null,
    min_sum: null,
    max_sum: null,
    fee_eur: null,
    fee_crypto: null
  })

  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const breakpoints = Grid.useBreakpoint();

  const isProfileApproved = useMemo(() => {
    return user?.confirm?.aml_status === AML_STATUS.APPROVED;
  }, [user]);

  const renderAMLAlert = useCallback(() => {
    switch (user.confirm.aml_status) {
      case AML_STATUS.NOT_CHECKED: {
        return <Alert message="You need to fill out a user questionnaire" action={
          <Button size="small" onClick={() => navigate(ROUTES.SURVEY)} type="text">
            Fill out the form
          </Button>
        } type="warning" closable showIcon />
      }
      case AML_STATUS.REJECTED: {
        return <Alert closable message="Your questionnaire was rejected" type="error" showIcon />
      }
      case AML_STATUS.MANUAL: {
        return <Alert closable message="Your questionnaire is under manual review" type="info" showIcon />
      }
      default: {
        return undefined;
      }
    }
  }, [user.confirm.aml_status]);

  const modalConfig = useMemo(() => ({
    keyboard: false,
    width: 700,
    centered: true,
    maskClosable: false,
    closable: false,
    footer: [
      <Row justify="end">
        <Button key="submit" onClick={() => {
          navigate(ROUTES.PROFILE);
        }} type="text">OK</Button>
      </Row>
    ],
    title: isUserVerified ? 'Please fill in the information in your account' : 'Confirm your account',
    content: isUserVerified
      ? 'In order to proceed further, it\'s required to complete your profile information. Please fill out all the necessary fields in your profile.'
      : 'Once your account has been verified, you\'ll be able to buy and sell ' +
      'cryptocurrency with confidence, knowing that your account has been fully vetted and approved.' +
      ' If you have any questions or need assistance with the verification process, please don\'t hesitate to contact our support team for help.'
  }), [isProfileApproved, isUserVerified]);

  useEffect(() => {
    dispatch(getTradeInfo(TRANSACTION_TYPE.BUY, { toState: false }))
    .then(resp => {
      setTradeData(resp.usdt)
      calculateFees(TRANSACTION_TYPE.SEND_FIAT, 'EUR', 1, false)
      .then(({ fee_eur, fee_crypto }) => {
        setTradeData(prevState => ({
          ...prevState,
          fee_eur,
          fee_crypto
        }))
      })
    })
  }, [])

  useEffect(() => {
    if (!isUserVerified || !isProfileApproved) modal.warning(modalConfig);
  }, [])

  const onFormSubmitSuccess = useCallback(({ iban, amount }) => {
    setLoading(true);
    dispatch(sendFiat(iban, amount)).finally(() => {
      setLoading(false);
      form.resetFields();
    });
  }, []);
  const sepaQRDataUrl = encodeURIComponent(`BCD\n002\n1\nSCT\n${user.fiat_topup_bic}\n${user.fiat_topup_name}\n${user.fiat_topup_iban}\n\n\n\n\n${user.fiat_topup}`);

  return (
    <Container>
      {contextHolder}

      <Typography.Title level={breakpoints.md ? 1 : 3}>Fiat Currency</Typography.Title>
      <Divider orientation="left">Deposit</Divider>
      <Space size="large" direction="vertical">
        <Space direction="vertical">
          <Typography.Text><span style={{ fontWeight: 'bold' }}>Beneficiary:</span> <Typography.Text keyboard
                                                                                                     copyable>{user.fiat_topup_name ?? '-/-'}</Typography.Text></Typography.Text>
          <Typography.Text><span style={{ fontWeight: 'bold' }}>IBAN:</span> <Typography.Text keyboard
                                                                                              copyable>{user.fiat_topup_iban ?? '-/-'}</Typography.Text></Typography.Text>
          <Typography.Text><span style={{ fontWeight: 'bold' }}>BIC:</span> <Typography.Text keyboard
                                                                                             copyable>{user.fiat_topup_bic ?? '-/-'}</Typography.Text></Typography.Text>
          <Typography.Text><span style={{ fontWeight: 'bold' }}>Reference:</span> <Typography.Text keyboard
                                                                                                   copyable>{user.fiat_topup ?? '-/-'}</Typography.Text></Typography.Text>
        </Space>

        <Typography.Paragraph type="secondary">Please transfer the desired amount in EUR to the above bank
          details. <Typography.Text type="secondary" underline>It's essential to include REFERENCE in the payment
            description.</Typography.Text> Ensure this text is entered correctly to facilitate a smooth deposit process.
          Once the transfer is completed, the funds will be credited to your fiat account.</Typography.Paragraph>

        <Row style={{ marginBottom: 20 }} justify="center"><QRCode errorLevel="H" icon={siteLogo}
                                                                   value={sepaQRDataUrl} /></Row>
      </Space>

      <Divider orientation="left">Send</Divider>

      <Form style={{ maxWidth: 600 }} validateMessages={validateMessages}
            disabled={loading || !isProfileApproved || !isUserVerified} validateTrigger="onChange" form={form}
            size="large" layout="vertical" onFinish={onFormSubmitSuccess}>
        <Form.Item rules={[...FIELDS_SCHEME.iban, ...GENERAL_SCHEME.required]} label="IBAN" name="iban">
          <Input maxLength={34} placeholder="Beneficiary IBAN" size="large" />
        </Form.Item>
        <Form.Item rules={GENERAL_SCHEME.required} label="Amount" name="amount">
          <InputNumber addonAfter={<Image preview={false} width={32} height={32} src={eurIcon} alt="currency" />}
                       className="w100" placeholder="Enter amount" min={0.01} />
        </Form.Item>
        <Typography style={{ marginBottom: 20 }}>A commission of {tradeData.fee_eur} EUR + {tradeData.fee}% of the
          transfer amount is charged for fiat withdrawals</Typography>

        <Row justify="center" style={{ marginBottom: 10 }}>
          <Button loading={loading} onClick={form.submit}>Send FIAT</Button>
        </Row>

        {renderAMLAlert()}
      </Form>
    </Container>
  );
};
const mapStateToProps = state => ({
  user: getUser(state),
  isUserVerified: getUser(state).confirm.sumsub_status === VERIFICATION_STATUS.VERIFIED,
})
export default connect(mapStateToProps)(FiatExchange);
