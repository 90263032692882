import React, { useCallback, useEffect, useMemo, useState } from "react";
import Container from "../../components/Container/Container";
import {
  Alert,
  Button,
  Col,
  Divider,
  Grid,
  Modal,
  Row,
  Segmented,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../store/selectors";
import { connect } from "react-redux";
import SellForm from "./components/SellForm/SellForm";
import BuyForm from "./components/BuyForm/BuyForm";
import { ROUTES } from "../../components/AppRouter/AppRouter";
import { AML_STATUS, VERIFICATION_STATUS } from "../../utils/constants";
import {
  buyCrypto,
  getTradeInfo,
  sellCrypto,
} from "../../store/actions/trades";
import { isProfileFilled } from "../../utils/helpers";

const Exchange = ({
  isUserVerified,
  user,
  userMeta,
  buyCryptoDispatched,
  getTradeInfoDispatched,
  sellCryptoDispatched,
}) => {
  const [direction, setDirection] = useState("Buy");

  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();
  const breakpoints = Grid.useBreakpoint();

  const isProfileApproved = useMemo(() => {
    return user?.confirm?.aml_status === AML_STATUS.APPROVED;
  }, [user]);

  const renderAMLAlert = useCallback(() => {
    switch (user.confirm.aml_status) {
      case AML_STATUS.NOT_CHECKED: {
        return (
          <Alert
            message="You need to fill out a user questionnaire"
            action={
              <Button
                size="small"
                onClick={() => navigate(ROUTES.SURVEY)}
                type="text"
              >
                Fill out the form
              </Button>
            }
            type="warning"
            closable
            showIcon
          />
        );
      }
      case AML_STATUS.REJECTED: {
        return (
          <Alert
            closable
            message="Your questionnaire was rejected"
            type="error"
            showIcon
          />
        );
      }
      case AML_STATUS.MANUAL: {
        return (
          <Alert
            closable
            message="Your questionnaire is under manual review"
            type="info"
            showIcon
          />
        );
      }
      default: {
        return undefined;
      }
    }
  }, [user.confirm.aml_status]);

  const modalConfig = useMemo(
    () => ({
      keyboard: false,
      width: 700,
      centered: true,
      maskClosable: false,
      closable: false,
      footer: [
        <Row justify="end">
          <Button
            key="submit"
            onClick={() => {
              navigate(ROUTES.PROFILE);
            }}
            type="text"
          >
            OK
          </Button>
        </Row>,
      ],
      title: isUserVerified
        ? "Please fill in the information in your account"
        : "Confirm your account",
      content: isUserVerified
        ? "In order to proceed further, it's required to complete your profile information. Please fill out all the necessary fields in your profile."
        : "Once your account has been verified, you'll be able to buy and sell " +
          "cryptocurrency with confidence, knowing that your account has been fully vetted and approved." +
          " If you have any questions or need assistance with the verification process, please don't hesitate to contact our support team for help.",
    }),
    [isProfileApproved, isUserVerified]
  );

  useEffect(() => {
    if (!isUserVerified || !isProfileApproved) modal.warning(modalConfig);
  }, [isUserVerified, isProfileApproved]);

  return (
    <Container>
      {contextHolder}

      <Typography.Title level={breakpoints.md ? 1 : 3}>
        Cryptocurrency Exchange
      </Typography.Title>
      <Typography.Paragraph>
        Online service Cryptolite.tech allows to buy, sell and exchange various
        types of cryptocurrencies - Bitcoin, Litecoin, Ethereum, DASH, Monero,
        Z-Cash, Decred and other. We accept international currencies. Low rates,
        fast processing of applications, technical support.
      </Typography.Paragraph>
      <Row>
        <Col xs={24} md={5}>
          <Segmented
            size="large"
            options={["Buy", "Sell"]}
            value={direction}
            onChange={setDirection}
            block
          />
        </Col>
      </Row>
      <Divider orientation="left">{`${direction} currency`}</Divider>
      <Row style={{ marginBottom: 10 }}>
        <Col xs={24} lg={10}>
          {direction === "Buy" ? (
            <BuyForm
              user={user}
              onSubmit={buyCryptoDispatched}
              getTradeInfo={getTradeInfoDispatched}
              isAvailable={isUserVerified && isProfileApproved}
            />
          ) : (
            <SellForm
              user={user}
              onSubmit={sellCryptoDispatched}
              getTradeInfo={getTradeInfoDispatched}
              isAvailable={isUserVerified && isProfileApproved}
            />
          )}
        </Col>
      </Row>
      {renderAMLAlert()}
    </Container>
  );
};
const mapStateToProps = (state) => ({
  isUserVerified:
    getUser(state).confirm.sumsub_status === VERIFICATION_STATUS.VERIFIED,
  userMeta: getUser(state).meta,
  user: getUser(state),
});
const mapDispatchToProps = {
  getTradeInfoDispatched: getTradeInfo,
  sellCryptoDispatched: sellCrypto,
  buyCryptoDispatched: buyCrypto,
};
export default connect(mapStateToProps, mapDispatchToProps)(Exchange);
