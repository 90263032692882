import React, {useCallback, useState} from 'react';
import Container from "../../components/Container/Container";
import {Button, Form, Grid, Input, message, Row, Space, Typography} from "antd";
import classes from './SignIn.module.scss';
import privacy from "../../assets/privacy_policy.pdf";
import {useDispatch} from "react-redux";
import {login} from "./actions";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../components/AppRouter/AppRouter";
import {FIELDS_SCHEME, GENERAL_SCHEME, validateMessages} from "../../schemas";
import {IS_DEV} from "../../utils/constants";
import terms from "../../assets/terms.pdf";

const SignIn = () => {
	const [loading, setLoading] = useState(false);

	const [form] = Form.useForm();
	const dispatch = useDispatch(); 
	const navigate = useNavigate();
	const breakpoints = Grid.useBreakpoint();

	const onFormSubmitSuccess = useCallback((formData) => {
		setLoading(true);
		dispatch(login({login: formData.email, password: formData.password})).catch((e) => {
			const errType = e?.data?.type;
			if (errType) navigate(errType === 'email' ? ROUTES.CONFIRM_EMAIL : ROUTES.CONFIRM_PHONE);
			else form.resetFields();
		}).finally(() => setLoading(false));
	}, []);

	return (
		<Container className={classes.container}>
			<Typography.Title level={breakpoints.md ? 1 : 3}>Login</Typography.Title>
			<Space align="center" direction="vertical">
				<Typography.Text>Not registered? <Typography.Link onClick={() => navigate(ROUTES.SIGN_UP)}>Create account</Typography.Link></Typography.Text>
				{/*<Typography.Link onClick={() => navigate(ROUTES.SIGN_UP_BUSINESS)} style={{ marginBottom: 40 }}>Create account for business</Typography.Link>*/}
			</Space>

			<Form disabled={loading} validateMessages={validateMessages} initialValues={IS_DEV && {
				email: 'test555@gmail.com',
				password: 'Qwerty12345'
			}} validateTrigger={["onBlur", "onSubmit"]} style={{ maxWidth: 410 }} className="w100" layout="vertical" form={form} size="large" onFinish={onFormSubmitSuccess}>
				<Form.Item label="Email" name="email" rules={[...FIELDS_SCHEME.email, ...GENERAL_SCHEME.required]}>
					<Input placeholder="email@example.com" />
				</Form.Item>
				<Form.Item label="Password" rules={[...FIELDS_SCHEME.password, ...GENERAL_SCHEME.required]} name="password">
					<Input.Password placeholder="********"/>
				</Form.Item>
				<Space direction="vertical" align="center" style={{ width: '100%', textAlign: 'center' }}>
					<Button loading={loading} style={{ maxWidth: 200 }} onClick={form.submit} type="primary" size="large">Sign in</Button>
					<Typography.Link onClick={() => navigate(ROUTES.RESTORE_PASS)}>Forgot password?</Typography.Link>
					<Typography.Text className="text-center">By continuing you accept our standard
						<Typography.Link href={terms} download="terms_and_conditions.pdf"> terms & conditions </Typography.Link> and
						<Typography.Link download="privacy.pdf" href={privacy}> our privacy policy.</Typography.Link></Typography.Text>
				</Space>
			</Form>
		</Container>
	);
};

export default SignIn;
